
import moment from "moment";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import useCallRequest from "@/composables/useCallRequest";
import useOrders from "@/composables/useOrders";
import CloseRequestModal from "@/components/modals/forms/CloseRequestModal.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import {
  AgentDto,
  CallRequestDto,
  CustomerDto,
  GetCallRequestForViewDto,
} from "@/shared/service-proxies/service-proxies";
import Swal from "sweetalert2";
import { formatDateTime } from "@/core/services/HelperServices";

export default defineComponent({
  components: {
    CloseRequestModal,
  },
  setup() {
    const { callRequestForView, getCallRequestForView } = useCallRequest();
    const { orderHistory, getOrderHistory } = useOrders();
    const route = useRoute();
    const router = useRouter();

    const breadcrumbs = {
      title: "CallRequest",
      breadcrumbs: ["ViewCallRequest"],
    };

    const callRequestId = computed(() => route.params.id);

    let modelValue = ref<GetCallRequestForViewDto>({
      callRequest: {
        customerId: "",
        agentId: "",
        requestTime: "",
        closeTime: "",
        status: "",
        closureComment: "",
        id: "",
      } as unknown as CallRequestDto,
      agent: {
        firstname: "",
        lastname: "",
        email: "",
        phoneNumber: "",
        userId: "",
        territoryId: "",
        id: "",
      } as unknown as AgentDto,
      customer: {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        outletName: "",
        outletAddress: "",
        landmark: "",
        retailChannel: "",
        region: "",
        state: "",
        lga: "",
        territoryId: "",
        loyaltyPoint: "",
        urno: "",
        rcS8Number: "",
        id: "",
      } as unknown as CustomerDto,
    } as unknown as GetCallRequestForViewDto);

    onMounted(async () => {
      if (!callRequestId.value) {
        router.push({ name: "callRequestsHistory" });

        Swal.fire("Call request id is required");
      }

      setCurrentPageBreadcrumbs("CallRequest", ["ViewCallRequest"]);

      await getCallRequestForView(callRequestId.value as string);

      modelValue.value =
        callRequestForView.value as unknown as GetCallRequestForViewDto;

      const customerId = modelValue.value.callRequest.customerId;
      if (customerId) {
        await getOrderHistory(customerId);
      }
    });

    return {
      formatDateTime,
      moment,
      orderHistory,
      router,
      modelValue,
      callRequestId,
      breadcrumbs,
    };
  },
});
