
import { defineComponent, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { hideModal } from "@/core/helpers/dom";
import useCallRequest from "@/composables/useCallRequest";
import { CloseCallRequestDto } from "@/shared/service-proxies/service-proxies";
import router from "@/router";
export default defineComponent({
  props: {
    callRequestId: {
      required: true,
      type: String,
    },
  },
  setup(props) {
    const formRef = ref<null | HTMLFormElement>(null);
    const CloseRequestModalRef = ref<null | HTMLElement>(null);
    const { saveClosureComment } = useCallRequest();

    const loading = ref<boolean>(false);
    const closureClomment = ref("");

    const submit = async () => {
      loading.value = true;

      saveClosureComment(
        {
          callRequestId: props.callRequestId,
          closureClomment: closureClomment.value,
        } as unknown as CloseCallRequestDto,
        undefined
      )
        .then(() => {
          Swal.fire({
            text: "Success!",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Okay!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          }).then(() => {
            hideModal(CloseRequestModalRef.value);
            loading.value = false;
            router.push({ name: "callRequestsHistory" });
          });
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            text: "An error occured with your request.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          }).then(() => {
            loading.value = false;
          });
        });
    };

    return {
      CloseRequestModalRef,
      closureClomment,
      formRef,
      loading,
      submit,
    };
  },
});
