import { ref, Ref } from "vue";
import { axiosClient, APP_BASE_URL } from "@/core/services/AxiosClient";

import {
  CallRequestServiceProxy,
  CloseCallRequestDto,
  GetCallRequestForViewDto,
  GetCallRequestStatisticsDto,
} from "@/shared/service-proxies/service-proxies";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { CallRequestHistoryFilter } from "@/models";
import { CancelToken } from "axios";

interface UsableOrders {
  globalQueue: Ref<Array<GetCallRequestForViewDto> | null>;
  myQueue: Ref<Array<GetCallRequestForViewDto> | null>;
  callRequestHistory: Ref<Array<GetCallRequestForViewDto> | undefined>;
  callRequestStats: Ref<Array<GetCallRequestStatisticsDto> | undefined>;
  callRequestForView: Ref<GetCallRequestForViewDto | undefined>;

  getGlobalQueue: () => void;
  getMyQueue: () => void;
  assignToMe: (id: string) => Promise<boolean | void>;
  getCallRequestHistory: (filter: CallRequestHistoryFilter) => Promise<void>;
  getCallRequestStat: () => Promise<void>;
  getCallRequestForView: (id: string) => Promise<void>;
  assignAgent: (
    callRequestId: string | undefined,
    agentId: string | undefined,
    cancelToken?: CancelToken | undefined
  ) => Promise<boolean | void>;
  saveClosureComment: (
    inputData: CloseCallRequestDto,
    cancelToken: CancelToken | undefined
  ) => Promise<boolean | void>;
}

const useOrders = (): UsableOrders => {
  const store = useStore();
  const client = new CallRequestServiceProxy(APP_BASE_URL, axiosClient);

  const globalQueue: Ref<Array<GetCallRequestForViewDto> | null> = ref(null);
  const myQueue: Ref<Array<GetCallRequestForViewDto> | null> = ref(null);
  const callRequestStats: Ref<Array<GetCallRequestStatisticsDto> | undefined> =
    ref(undefined);
  const callRequestHistory: Ref<Array<GetCallRequestForViewDto> | undefined> =
    ref(undefined);

  const callRequestForView: Ref<GetCallRequestForViewDto | undefined> =
    ref(undefined);

  const getGlobalQueue = async () => {
    await client
      .getGlobalQueue()
      .then((data) => {
        globalQueue.value = data;
      })
      .catch((error) => {
        store.commit(Mutations.SET_ERROR, error);
      });
  };

  const getMyQueue = async () => {
    await client
      .getMyQueue()
      .then((data) => {
        myQueue.value = data;
      })
      .catch((error) => {
        store.commit(Mutations.SET_ERROR, error);
      });
  };

  const getCallRequestHistory = async (filter: CallRequestHistoryFilter) => {
    const {
      uRNOFilter,
      callRequestStatusFilter,
      sorting,
      skipCount,
      maxResultCount,
      cancelToken,
    } = filter;

    await client
      .getAll(
        uRNOFilter,
        callRequestStatusFilter,
        sorting,
        skipCount,
        maxResultCount,
        cancelToken
      )
      .then((data) => {
        const { items } = data;
        callRequestHistory.value = items;
      })
      .catch((error) => {
        store.commit(Mutations.SET_ERROR, error);
      });
  };

  const getCallRequestStat = async () => {
    await client
      .getStatistics()
      .then((data) => {
        callRequestStats.value = data;
      })
      .catch((error) => {
        store.commit(Mutations.SET_ERROR, error);
      });
  };

  const assignAgent = async (
    callRequestId: string | undefined,
    agentId: string | undefined,
    cancelToken?: CancelToken | undefined
  ) => {
    const resp = await client
      .assignToAgent(callRequestId, agentId, cancelToken)
      .then(() => {
        return true;
      })
      .catch((error) => {
        store.commit(Mutations.SET_ERROR, error);
        return false;
      });
    return resp;
  };

  const getCallRequestForView = async (id: string) => {
    await client
      .getCallRequestForView(id)
      .then((data) => {
        callRequestForView.value = data;
      })
      .catch((error) => {
        store.commit(Mutations.SET_ERROR, error);
      });
  };

  const saveClosureComment = async (
    inputData: CloseCallRequestDto,
    cancelToken: CancelToken | undefined
  ) => {
    const resp = await client
      .closeCallRequest(inputData, cancelToken)
      .then(() => {
        return true;
      })
      .catch((error) => {
        store.commit(Mutations.SET_ERROR, error);
        return false;
      });
    return resp;
  };

  async function assignToMe(id: string) {
    await client
      .assignToMe(id)
      .then(() => {
        return true;
      })
      .catch((error) => {
        store.commit(Mutations.SET_ERROR, error);
      });
  }

  return {
    globalQueue,
    myQueue,
    callRequestStats,
    callRequestHistory,
    callRequestForView,
    getGlobalQueue,
    assignAgent,
    getMyQueue,
    assignToMe,
    getCallRequestStat,
    getCallRequestHistory,
    getCallRequestForView,
    saveClosureComment,
  };
};

export default useOrders;
